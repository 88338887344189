import Vue from 'vue/dist/vue.esm'
import SecureImage from './components/SecureImage'
import TurbolinksAdapter from 'vue-turbolinks'

Vue.use(TurbolinksAdapter)

const initVueOrder = () => {
  const $el = document.getElementById('inspection-photos-form')

  if ($el) {
    new Vue({
      el: $el,
      data: {},
      components: { SecureImage },
      methods: {}
    })
  }
}

document.addEventListener('turbolinks:load', initVueOrder)
