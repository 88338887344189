// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import './demo-images'
import './vue/inspection-photos'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

function setInspection (val) {
  let inspection_input = document.getElementById('inspection_id')
  if (inspection_input) {
    inspection_input.value = val
  }
}

function setNcMerchandise (val) {
  let nc_merchandise_input = document.getElementById('nc_merchandise_id')
  if (nc_merchandise_input) {
    nc_merchandise_input.value = val
  }
}

window.bootstrap = bootstrap
window.setInspection = setInspection
window.setNcMerchandise = setNcMerchandise