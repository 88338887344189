<template>
  <img :src="src" :alt="alt" :title="title" :class="classes" :style="styles" v-on:error="setDefault" ref="img" />
</template>

<script>
export default {
  props: {
    alt: { type: String, required: false },
    classes: { type: String, required: false },
    defaultSrc: { type: String, required: false },
    src: { type: String, required: false },
    styles: { type: String, required: false },
    title: { type: String, required: false }
  },
  data () {
    return {
      firstAttempt: true
    }
  },
  mounted () {
    if (!this.src && this.defaultSrc) {
      this.setDefault()
    }
  },
  methods: {
    setDefault () {
      if (this.firstAttempt && this.defaultSrc && this.$refs.img) {
        this.firstAttempt = false
        this.$refs.img.src = this.defaultSrc
      }
    }
  }
}
</script>
