document.addEventListener('turbolinks:load', () => {
  const imagesContainer = document.querySelector('#demo-images')
  if (imagesContainer) {
    imagesContainer.addEventListener('click', (e) => {
      const imgElement = e.target
      if (imgElement.tagName === 'IMG') {
        const modalElement = document.getElementById('exampleModal')
        if (modalElement) {
          const myModal = new bootstrap.Modal(modalElement)
          modalElement.querySelector('.modal-body').innerHTML = imgElement.outerHTML
          myModal.show()
        }
      }
    })
  }
})
